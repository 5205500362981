/* add custom style here */
nav#AccessibleNav {
    border: transparent;
}
a.btn {
    background: #278c29;
}
.site-header__logo-image--centered img {
    max-width: 150px !important;
    padding: 5px;
}
header.site-header a, header.site-header button {
    color: #000;
}
#shopify-section-feature-row a.btn {
    background-color: transparent;
    color: #000;
    border-color: #000;
    padding: 10px 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    white-space: normal;
    font-size: 12px;
    transition: all .2s ease-in-out;
}
#shopify-section-collection h2 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
}
#shopify-section-collection a.grid-view-item__link {
    position: relative;
  	border: 1px solid #f7f7f7;
    padding: 10px;
}
#shopify-section-collection a.grid-view-item__link span.product-tags {
    position: absolute;
    left: 10px;
    top: 10px;
    background: #333;
    color: #fff;
    letter-spacing: 2px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 6px 12px;
}
#shopify-section-collection a.grid-view-item__link img {
    height: 525px;
    object-fit: cover;
    width: 100%;
    overflow: hidden;
}
#shopify-section-collection a.grid-view-item__link .h4 {
        display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
    color: #000;
    margin: 15px 0 5px;
}
#shopify-section-collection a.grid-view-item__link .h5.colou-count {
    font-size: 14px;
    font-weight: 400;
  margin-bottom: 10px;
}
#shopify-section-collection a.grid-view-item__link .price__regular {
    margin: 0;
}

div#shopify-section-16247890166459f30f a.btn {
    background-color: transparent;
    color: #000;
    border-color: #000;
    padding: 10px 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    white-space: normal;
    font-size: 12px;
    transition: all .2s ease-in-out;
}
div#shopify-section-16247890166459f30f .text-center, div#shopify-section-1624789958b9eabee7 .text-center {
    text-align: left !important;
}
div#shopify-section-16247890166459f30f h2, div#shopify-section-1624789958b9eabee7 h2 {
    font-size: 22px;
    text-transform: none;
}

div#shopify-section-1624789958b9eabee7 {
    background: #000;
}
div#shopify-section-1624789958b9eabee7 a.btn {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
    padding: 10pxcolor: #fff; 18px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .08em;
    white-space: normal;
    font-size: 12px;
    transition: all .2s ease-in-out;
}
div#shopify-section-1624789958b9eabee7 h2 {
    font-size: 22px;
    text-transform: none;
  color: #fff;
  
}
div#shopify-section-1624789958b9eabee7 p {
  color: #fff;
  
}

div#shopify-section-1624954293762c9a36 h2 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
}


.collection-hero__title-wrapper::before {
    background-color: #000;
}



div#shopify-section-feature-row h2 {
    color: #000;
}

div#shopify-section-1624954293762c9a36 .slider-item {
    padding: 0 15px;
    text-align: center;
}
div#shopify-section-1624954293762c9a36 .box {
    padding: 25% 0;
    margin: 0 0 20px;
}
div#shopify-section-1624954293762c9a36 .custom-image-bar__text h3 {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
    color: #000;
    margin: 0 0 8px;
}
div#shopify-section-1624954293762c9a36 .custom-image-bar__text p {
    font-size: 15px;
    color: #000;
}


.template-collection a.grid-view-item__link {
    position: relative;
    border: 1px solid #f7f7f7;
    padding: 10px;
}
.template-collection a.grid-view-item__link span.product-tags {
    position: absolute;
    left: 10px;
    top: 10px;
    background: #333;
    color: #fff;
    letter-spacing: 2px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 6px 12px;
}
.template-collection a.grid-view-item__link img {
    height: 320px;
    object-fit: cover;
    width: 100%;
    overflow: hidden;
}
.template-collection a.grid-view-item__link .h4 {
        display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    text-transform: capitalize;
    color: #000;
    margin: 13px 0 5px;
}
.template-collection a.grid-view-item__link .h5.colou-count {
    font-size: 14px;
    font-weight: 400;
  margin-bottom: 10px;
}
header.collection-header .text-center {
    margin: 50px auto;
  font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
}
header.collection-header .text-center p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
}

.product-template__container {
    max-width: 1600px;
}
.product-single__media-group {
    display: flex;
    flex-wrap: wrap;
}
.product-single__media-group .product-single__media-wrapper {
    width: 50%;
    padding: 15px;
}
.product-single__meta span.product-tags {
    text-transform: uppercase;
    color: #333;
    display: inline-block;
    padding: 6px 12px;
    background: #f7f7f7;
    letter-spacing: 2px;
    font-size: 12px;
    margin: 0 0 15px;
}
.product-single__meta h1 {
    font-weight: 700;
    line-height: 1.2;
    word-wrap: break-word;
    text-transform: capitalize;
    font-size: 22px;
    color: #000;
}
.product-single__meta .product-star-reviews {
    margin-bottom: 10px;
}
.product-single__meta button {
    font-weight: 500 !important;
  border-radius: 0 !important;
}
.product-single__meta select {
    border-radius: 0;
    border-color: #dadada;
}
.product-single__description p {
    color: #000;
    font-weight: 400;
    line-height: 30px;
}

.product-single__description  .faqpage .accordion {
    background: #fff;
    color: #000;
    overflow: hidden;
  cursor: pointer;
}
.product-single__description .faqpage .container {
    width: 100%;
    padding: 0 !important;
    overflow: hidden;
}
.product-single__description .faqpage .accordion::after {
    line-height: 23px;
    height: 20px;
    font-family: FontAwesome;
    content: "\f107";
    font-size: 26px;
    color: #000;
}
.product-single__description .faqpage .accordion.accordion-open::after {
    content: "\f106";
}
.product-single__description .faqpage .container .content {
    margin: 0 0 10px;
    border: transparent;
    background: #fff;
}
.product-single .product-single__media a::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.product-single .product-single__media a svg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    margin: auto;
    z-index: 9;
  -webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.product-single .product-single__media a svg, .product-single .product-single__media a::after {
    opacity: 0;
    visibility: hidden;
}
.product-single .product-single__media:hover a svg, .product-single .product-single__media:hover a::after {
    opacity: 1;
    visibility: visible;
}
.product-single .product-single__media a svg:hover circle {
    fill-opacity: 1;
   -webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
}
.product-single__meta .swatch .header {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
}
.product-single__meta .swatch .available {
    width: 30%;
}
.product-single__meta label {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
}
.social-sharing a.btn {
    background: #fff;
}

.product-single__meta .swatch .available input[type=radio] + label {
  border: 1px solid #dadada;
    box-shadow: none;
    width: 100%;
    font-size: 13px;
    padding: 1px 5px;
    touch-action: manipulation;
    height: 100% !important;
    color: #000;
    font-weight: 300 !important;
    text-transform: capitalize !important;
    letter-spacing: 2px;
    background: #fff;
    border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
-moz-transition: all 0.3s ease-in-out;
-o-transition: all 0.3s ease-in-out;
transition: all 0.3s ease-in-out;
} 
.product-single__meta .swatch .available input[type=radio]:checked + label {
    background: #000;
    color: #fff;
}


.product-recommendations__inner .section-header h2 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
}
.product-recommendations__inner a.grid-view-item__link {
    position: relative;
    border: 1px solid #f7f7f7;
    padding: 10px;
}
.product-recommendations__inner a.grid-view-item__link span.product-tags {
    position: absolute;
    left: 10px;
    top: 10px;
    background: #333;
    color: #fff;
    letter-spacing: 2px;
    font-size: 11px;
    text-transform: uppercase;
    padding: 6px 8px;
}
.product-recommendations__inner a.grid-view-item__link img {
    height: 225px;
    object-fit: cover;
    width: 100%;
    overflow: hidden;
}
.product-recommendations__inner a.grid-view-item__link .h4 {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    color: #000;
    margin: 0 0 5px;
}
.product-recommendations__inner a.grid-view-item__link .h5.colou-count {
    font-size: 14px;
    font-weight: 400;
  margin-bottom: 10px;
}
#shopify-product-reviews .new-review-form input.button {
    float: left;
    font-weight: 400;
}
#shopify-product-reviews h2 {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
}
.collection-hero h1 {
    font-weight: 600;
    font-size: 25px;
    letter-spacing: 1px;
}

.newsletter-section button.btn {
    background: #278c29;
    font-weight: 500;
}

.site-footer .h4, .site-footer h4 {
      color: #000;
  }

footer.site-footer {
    background-color: #fff;
}
.site-footer button.btn.newsletter__submit {
    background: #278c29;
}


/********** dsk slider ***********/
.slick-lightbox-inner {
    top: 50%;
    left: 50%;
    width: 95%;
    transform: translate(-50%,-50%);
    height: 95%;
    margin: 0 auto;
    background-color: #f5f5f5;
}
.slick-lightbox-inner .slick-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
}
.slick-lightbox-inner .slick-dots li {
    background: #084052;
    border-radius: 50%;
    height: 5px;
    width: 5px;
}
.slick-lightbox-inner .slick-dots li button {
    width: 100%;
    height: 100%;
}
.slick-lightbox-inner .slick-dots li.slick-active {
    transform: scale(2);
}
.slick-lightbox-inner button.slick-arrow {
    background: #fff;
    color: #333;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    z-index: 99;
}
.slick-lightbox-inner button.slick-arrow::before {
    font-family: 'FontAwesome';
    color: #000;
    font-size: 25px;
}
.slick-lightbox-inner button.slick-prev::before {
    content: "\f104";
}
.slick-lightbox-inner button.slick-next::before {
    content: "\f105";
}
.slick-lightbox-close {
    right: 25px;
    top: 15px;
}
.slick-lightbox-close:before {
    font-family: slick;
    font-size: 60px;
    line-height: 1;
    color: #000;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "×";
  line-height: 30px;
}

.product-form .color_sec, .product-form .grip_sec {
    display: inline-block;
    vertical-align: top;
    width: 50%;
}

/************** Start Responsive **********/

@media (max-width: 1024px) {
  #shopify-section-collection a.grid-view-item__link img {
      height: 455px;
  }
  .template-collection a.grid-view-item__link img {
      height: 290px;
  }
  .product-single__meta .product-form__item {
      flex-basis: 100%;
  }
  .site-footer .h4, .site-footer h4 {
      font-size: 16px;
  }
  .product-single__meta h1 {
      font-size: 18px;
  }
}


@media (max-width: 768px) {
  #shopify-section-collection a.grid-view-item__link img {
      height: 325px;
  }
  .template-collection a.grid-view-item__link img {
    height: 170px;
    margin-bottom: 10px;
}
}

@media (max-width: 749px) {
  .mobile-nav li {
      border: transparent;
  }
  .mobile-nav li .mobile-nav__return {
      border: transparent;
  }
  .mobile-nav li span.mobile-nav__label {
      color: #000;
      letter-spacing: 1px;
  }
  
  /*********** Mobile Slider ******/
/*   .slick-lightbox-inner {
      width: 95%;
      background-color: #f5f5f5;
  } */
  
  .product-single-responsive-view .slick-dots {
      position: absolute;
      bottom: 20px;
      width: 100%;
  }
  .product-single-responsive-view .slick-dots li {
      background: #084052;
      border-radius: 50%;
      height: 5px;
      width: 5px;
  }
  .product-single-responsive-view .slick-dots li button {
      width: 100%;
      height: 100%;
  }
  .product-single-responsive-view .slick-dots li.slick-active {
      transform: scale(2);
  }
  .product-single-responsive-view button.slick-arrow {
     background: #e4e4e4;
    color: #333;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    z-index: 99;
  }
  .product-single-responsive-view button.slick-arrow::before {
      font-family: 'FontAwesome';
      color: #000;
      font-size: 25px;
  }
  .product-single-responsive-view button.slick-prev::before {
      content: "\f104";
  }
  .product-single-responsive-view button.slick-next::before {
      content: "\f105";
  }
  .product-single-responsive-view button.slick-prev {
      left: -10px;
  }
  .product-single-responsive-view button.slick-next {
      right: -10px;
  }
  .grid.product-single {
      margin-left: 0;
  }
  .grid.product-single .product-single-responsive-view {
      display: block;
      padding: 0;
      width: 100%;
  }
  .product-single__meta .swatch .available {
      width: 46%;
  }
}



@media (max-width: 767px) {
  .site-header__logo-image--centered img {
      margin: 0;
      max-width: 120px !important;
      padding: 5px;
  }
  .announcement-bar .announcement-bar__message {
      color: #3a3a3a;
      font-size: 14px;
      padding: 10px 15px 0;
  }
  header.site-header {
    border: transparent;
}
  div#shopify-section-feature-row h2 {
      font-size: 18px;
  }

  .product-single__meta h1 {
      font-size: 22px;
  }
  .product-single button.btn.product-form__cart-submit.btn--secondary-accent {
      border-width: 2px;
  }
  div#shopify-section-1624954293762c9a36 .custom-image-bar__text h3 {
      font-size: 16px;
  }
  #shopify-section-collection hr.hr--invisible {
      margin: 15px 0;
  }
  .newsletter-section {
      padding-top: 0;
  }
  .newsletter-section form {
      margin: 0;
  }
  div#shopify-section-16247890166459f30f {
      padding: 10px 0 30px;
  }
  div#shopify-section-16247890166459f30f h2, div#shopify-section-1624789958b9eabee7 h2 {
      font-size: 18px;
  }
  div#shopify-section-16247890166459f30f p, div#shopify-section-1624789958b9eabee7 p, div#shopify-section-16247902208203e773 p {
      font-size: 13px;
      line-height: 25px;
  }
  div#shopify-section-16247902208203e773 h2 {
      font-size: 18px;
  }
  
  #shopify-section-collection a.grid-view-item__link span.product-tags {
      font-size: 10px;
      padding: 5px 8px;
  }
  #shopify-section-collection a.grid-view-item__link .h4 {
      font-size: 13px;
  }
  .newsletter-section h2 {
      color: #000;
      font-size: 15px;
      margin-bottom: 10px;
  }
  .newsletter-section input {
      font-weight: 300;
      font-size: 15px;
  }
  
  
  header.collection-header .text-center {
      margin: 30px auto;
      font-size: 13px;
  }
  .template-collection a.grid-view-item__link span.product-tags {
      font-size: 10px;
      padding: 4px 8px;
  }
  .template-collection a.grid-view-item__link img {
      height: 260px;
      margin-bottom: 20px;
  }
  .template-collection a.grid-view-item__link .h4 {
      font-size: 13px;
      line-height: 22px;
  }
  .product-recommendations__inner a.grid-view-item__link .h4 {
      line-height: 24px;
  }
  
  .site-footer .h4, .site-footer h4 {
      font-size: 14px;
      margin-bottom: 5px;
  }
  footer .site-footer__item {
      margin-bottom: 30px;
  }
  footer ul.site-footer__linklist li a {
      font-size: 14px;
      font-weight: 300;
  }
  
}

@media (max-width: 568px) {
  .template-collection a.grid-view-item__link img {
      height: 150px;
  }

}

.collection-list-mob .collection-grid-item__title {
  color: inherit;
  position: inherit;
  -webkit-transform: inherit;
  transform: inherit;
  text-shadow: inherit;
}

.collection-list-mob .customize-collection-grid-item {
  border: 1px #ccc solid; 
  padding-bottom: 15px;
}

.custom-content-mob {
  padding-bottom: 0px;
}
.custom-border {
  border: 1px #ccc solid; 
  padding-bottom: 10px;
}
.custom-content-mob .grid-view-item__image-wrapper.product-card__image-wrapper {
 	margin-bottom: 0; 
}
.template-cart .cart__submit.btn, .add-btn.btn.product-form__cart-submit {
 	background-color: #278c29;
  	color: #fff;
}

/* SK CSS */

.product-form--payment-button-no-variants {
    max-width: 100%;
}

.mw-option__button {
    width: 100%;
    text-align: center;
    border-radius: 0 !important;
    padding: 15px 0 !important;
    font-weight: 300 !important;
    border: 1px solid #ddd !important;
}
.mw-option__value--selected .mw-option__button {
    background: #000;
    color: #fff;
  box-shadow: none !important;
}
.mw-stack {
    flex-wrap: unset !important;
}

.variant-style .mw-option__value {
    width: 30.33%;
}

.variant-pack .mw-option__value {
    width: 47%;
}

.variant-koala-grip .mw-option__value {
    width: 47%;
}
.variant-colour .mw-option__value {
    width: 47%;
}

.variant-size .mw-option__value {
    width: 100%;
}
.variant-colour .mw-option__swatch-media {
      box-shadow: none !important;
    border: 1px solid #ddd !important;
    border-radius: 0;
    width: 100%;
    height: 45.6px;
    background: none !important;
}
.variant-colour .mw-stack__item {
    flex: 0 0 auto;
    min-width: 0;
    max-width: 100%;
    width: 100%;
}
.variant-colour .mw-stack {
    width: 100%;
}


.product-form__item--no-variants {
    max-width: 100% !important;
      padding: 0;  
    margin: 25px 0 0;

}

.product-form__item--no-variants .mw-apo-add-to-cart-cloned {
    background: none !important;
    border: 2px solid #000;
    color: #000 !important;
}

#product_form_6878947410075 .swatches, #product_form_6878963466395 .swatches {
  display: none; 
}

@media (max-width: 767px) {
  .hero__inner .hero__btn {
    margin-top: 99px !important;
    position: absolute;
    margin-left: -63px;
    font-size: 16px !important;
  }
}

.image-with-text-overlay .page-width {
  padding-left: 0px;
  padding-right: 0px;
}

@media only screen and (min-width: 750px) {
  .template-index .main-content, .template-index .main-content .image-with-text-overlay {
      padding-top: 0px; 
  }
}

.mw-option__value.mw-option__value--selected .mw-option__swatch-media.mw-option__swatch-media--type-image {
 	background: #000 !important;
}

.shopify-payment-button .shopify-payment-button__more-options {
 	display: none; 
}

/* SK CSS */
.index-section {
    padding-bottom: 0 !important;
}
.section-header {
    margin-bottom: 0 !important;
}
hr.hr--invisible {
    margin: 15px 0 !important;
}
div#shopify-section-1624788898dc1d743c .form-single-field {
    margin: 15px auto 0px;
    max-width: 35rem;
}
div#shopify-section-1624788898dc1d743c  .newsletter-section {
    padding-top: 0;
}
footer.site-footer {
    margin: 0;
}
.site-footer__hr {
    border-bottom: 1px solid #dddddd;
    margin-top: 0;
}
header.collection-header .text-center {
    margin: 50px auto 15px;
}